import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Logo from '../../assets/images/logo-horizontal.svg';
import MenuIcon from '../../assets/images/menu.svg';
import { Link as Lenk } from 'react-scroll';
export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      affix: false,
    };
  }

  static propTypes = {
    siteTitle: PropTypes.string,
    menu: PropTypes.array.isRequired,
    toggleMenu: PropTypes.func.isRequired,
  };
  static defaultProps = {
    siteTitle: ``,
  };

  handleScroll = () => {
    const nav = document.querySelector('nav');
    const navTop = nav.offsetTop;
    if (window.scrollY > navTop) {
      this.setState({ affix: true });
    } else {
      this.setState({ affix: false });
    }
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  render() {
    const { menu, toggleMenu } = this.props;

    return (
      <header
        id="nav"
        className={`w-full z-10 py-4 transition-bg transition-250 ${
          this.state.affix
            ? 'fixed top-0 left-0 right-0 bg-gray-800'
            : 'absolute top-0 left-0 right-0 mt-8'
        }`}
      >
        <div className="container mx-auto px-4 md:px-0">
          <div className="flex items-center">
            <div>
              <Logo className="w-48 md:w-72 text-white fill-current"></Logo>
            </div>
            <div className="ml-auto flex items-center">
              <Lenk
                aria-label="Gå til kontaktskjema"
                to="kontakt-oss"
                smooth={true}
                duration={500}
                offset={-87}
                className="bg-white hidden sm:flex hover:bg-gray-300 transition-all transition-250 text-blue-800 font-thin h-12 px-4 rounded-full shadow-xl items-center justify-center cursor-pointer"
              >
                Ta kontakt
              </Lenk>
              <button
                aria-label="Åpne meny"
                className={`ml-10 transition-bg transition-250 text-blue-800 font-thin rounded-full shadow-xl h-12 w-12 flex items-center justify-center ${
                  this.state.affix
                    ? 'bg-gray-400 hover:bg-gray-200'
                    : 'bg-gray-900 hover:bg-gray-800'
                }`}
                onClick={toggleMenu}
              >
                <MenuIcon
                  className={`w-3 transition-all transition-250  fill-current ${
                    this.state.affix ? 'text-gray-800' : 'text-white'
                  }`}
                ></MenuIcon>
              </button>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
