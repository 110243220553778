/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Header from './header';
import './layout.css';
import { stack as Menu } from 'react-burger-menu';
import { Link as Lenk } from 'react-scroll';
import DeliveryIcon from '../../assets/images/mobile-devices.svg';
import WhoAreWeIcon from '../../assets/images/user-group.svg';
import ThumbsUpIcon from '../../assets/images/thumbs-up.svg';
import ContactUsIcon from '../../assets/images/phone.svg';
import Facebook from '../../assets/images/facebook-f.svg';
import Instagram from '../../assets/images/instagram.svg';
import Home from '../../assets/images/home.svg';
import { motion } from 'framer-motion';

const headingVariant = {
  ready: {
    x: 0,
    opacity: 1,
    transition: {
      type: 'spring',
      delay: 0.4,
    },
  },
  unready: {
    x: -100,
    opacity: 0,
  },
};

const textVariant = {
  ready: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.8,
    },
  },
  unready: {
    y: 100,
    opacity: 0,
  },
};

const svgVariant = {
  ready: {
    opacity: 1,
    transition: {
      ease: 'easeIn',
      delay: 1,
      duration: 0.6,
    },
  },
  unready: {
    opacity: 0,
  },
};

const Layout = ({ children }) => {
  const [state, setState] = useState({
    isOpen: false,
    isReady: false,
  });

  useEffect(() => {
    setState({
      ...state,
      isReady: true,
    });
  }, []);

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          menuLinks {
            name
            link
          }
        }
      }
    }
  `);
  const handleSetActive = to => {};

  const toggleMenu = () => {
    setState({
      ...state,
      isOpen: !state.isOpen,
    });
  };

  const closeMenu = () => {
    setState({ ...state, isOpen: false });
  };

  const handleStateChange = s => {
    setState({ ...state, isOpen: s.isOpen });
  };

  return (
    <>
      <div className="font-sans">
        <Header
          siteTitle={data.site.siteMetadata.title}
          menu={data.site.siteMetadata.menuLinks}
          toggleMenu={toggleMenu}
          closeMenu={closeMenu}
          onClick={() => closeMenu()}
        />
        <Menu isOpen={state.isOpen} onStateChange={state => handleStateChange(state)} right>
          <Lenk
            aria-label="Naviger til hva vi leverer"
            activeClass="active"
            to="hva-vi-leverer"
            spy={true}
            smooth={true}
            duration={500}
            offset={-87}
            onSetActive={handleSetActive}
            onClick={() => closeMenu()}
          >
            <div className="flex items-center mb-4">
              <DeliveryIcon className="h-4 w-4 fill-current mr-4"></DeliveryIcon>
              <div>Hva vi leverer</div>
            </div>
          </Lenk>
          <Lenk
            aria-label="Naviger til hvem er vi"
            activeClass="active"
            to="hvem-er-vi"
            spy={true}
            smooth={true}
            duration={500}
            offset={-87}
            onSetActive={handleSetActive}
            onClick={() => closeMenu()}
          >
            <div className="flex items-center mb-4">
              <WhoAreWeIcon className="h-4 w-4 fill-current mr-4"></WhoAreWeIcon>
              <div>Hvem er vi</div>
            </div>
          </Lenk>
          <Lenk
            aria-label="Naviger til referanser"
            activeClass="active"
            to="referanser"
            spy={true}
            smooth={true}
            duration={500}
            offset={-87}
            onSetActive={handleSetActive}
            onClick={() => closeMenu()}
          >
            <div className="flex items-center mb-4">
              <ThumbsUpIcon className="h-4 w-4 fill-current mr-4"></ThumbsUpIcon>
              <div>Referanser</div>
            </div>
          </Lenk>
          <Lenk
            aria-label="Naviger til kontakt oss"
            activeClass="active"
            to="kontakt-oss"
            spy={true}
            smooth={true}
            duration={500}
            offset={-87}
            onSetActive={handleSetActive}
            onClick={() => closeMenu()}
          >
            <div className="flex items-center mb-4">
              <ContactUsIcon className="h-4 w-4 fill-current mr-4"></ContactUsIcon>
              <div>Kontakt oss</div>
            </div>
          </Lenk>
        </Menu>
        <div className="bg-blue-700 -skew-y-7 py-80 -mt-48">
          <div className="skew-y-7">
            <div className="container mx-auto ">
              <div className="flex flex-col lg:flex-row text-white -mb-88 lg:mt-24 px-4 text-center lg:text-left lg:px-0">
                <div className="w-full lg:w-1/2 mb-24 lg:mb-0">
                  <motion.h1
                    animate={state.isReady ? 'ready' : 'unready'}
                    variants={headingVariant}
                    initial="unready"
                    className="text-4xl mt-12"
                  >
                    Vi leverer <span className="font-bold leading-none">knaillgode</span> nettsider
                    og applikasjoner
                  </motion.h1>
                  <div className="">
                    <motion.p
                      animate={state.isReady ? 'ready' : 'unready'}
                      variants={textVariant}
                      initial="unready"
                      className="mt-8 font-thin"
                    >
                      Naust Interaktiv AS har konsolidert med Webium AS. Besøk oss på <a href="https://webium.no">webium.no</a>
                    </motion.p>
                  </div>
                </div>
                <div className="w-full lg:w-1/2">
                  <motion.div
                    className="flex"
                    animate={state.isReady ? 'ready' : 'unready'}
                    variants={svgVariant}
                    initial="unready"
                  >
                    <Home className="w-full sm:w-120 h-auto sm:mx-auto lg:ml-auto"></Home>
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <main>{children}</main>
          <footer className="w-full bg-gray-800 text-gray-100 py-24">
            <div className="container mx-auto">
              <div className="flex px-4 md:px-0">
                <div className="w-full md:w-1/3 text-sm font-thin">
                  <div>
                    <h3 className="font-bold text-sm">Kontaktinformasjon</h3>
                    <div className="mt-6">
                      Du kan besøke oss i Svolvær, Lofoten. Havnepromenaden 2, 8300 Svolvær. Vi
                      holder til i 2. etasje over Bacalao.
                    </div>
                    <div className="mt-4">
                      Send oss en e-post på{' '}
                      <a
                        aria-label="Send mail til marius@naustinteraktiv.no"
                        className="text-blue-300"
                        href="mailto: marius@naustinteraktiv.no"
                      >
                        marius@naustinteraktiv.no
                      </a>{' '}
                      eller ring oss på{' '}
                      <a aria-label="Ring til 97 42 88 08" className="text-blue-300" href="tel:97428808">
                        97 42 88 08
                      </a>
                      .
                    </div>
                    <div className="mt-8">
                      <h3 className="font-bold text-sm">Følg oss på</h3>
                      <div className="mt-3 flex">
                        <a
                          aria-label="Gå til facebook-siden til naust interaktiv"
                          href="https://www.facebook.com/naustinteraktiv/"
                        >
                          <Facebook className="h-4 w-4 fill-current text-gray-200"></Facebook>
                        </a>
                        <a  aria-label="Gå til instagram-siden til naust interaktiv" href="https://www.instagram.com/naustinteraktiv/">
                          <Instagram className="h-4 w-4 fill-current text-gray-200"></Instagram>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
